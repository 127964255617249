import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels } from 'wsm-common-data';
import { useMyCarsSiteProperties } from '../../../../hooks';

const ModernMyCarsDetails = ({ link, onClick, isInline, title, isDesktop }) => {
	const labels = useLabels();
	const ariaVehicleTitle = title ? title.join(' ') : '';
	const detailsAriaPreface = labels.get('DETAILS_FOR_THIS');
	const ariaLabel = `${detailsAriaPreface} ${ariaVehicleTitle}`;
	const { enableMyCars } = useMyCarsSiteProperties();

	const detailsIconClasses = [
		'ddc-icon',
		'ddc-icon-arrow-details',
		isInline || !enableMyCars ? 'ml-2' : '',
		isDesktop ? 'ddc-icon-size-small' : ''
	];

	return (
		<a
			className={setClassNames([
				'modern-my-cars-details-label',
				'd-flex',
				'align-items-center',
				'p-0',
				'text-muted',
				isInline || !enableMyCars ? 'flex-row' : 'flex-column',
				isInline || !enableMyCars ? 'ml-auto' : ''
			])}
			href={link}
			onClick={onClick}
			aria-label={ariaLabel}
		>
			{!isInline && enableMyCars && (
				<i
					aria-hidden="true"
					className={setClassNames(detailsIconClasses)}
				/>
			)}
			<span>{labels.get('DETAILS')}</span>
			{(isInline || !enableMyCars) && (
				<i
					aria-hidden="true"
					className={setClassNames(detailsIconClasses)}
				/>
			)}
		</a>
	);
};

ModernMyCarsDetails.propTypes = {
	link: PropTypes.string,
	onClick: PropTypes.func,
	isInline: PropTypes.bool,
	title: PropTypes.arrayOf(PropTypes.string),
	isDesktop: PropTypes.bool
};

export default ModernMyCarsDetails;
