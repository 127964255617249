import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { useDeviceType } from '../../../../hooks';
import { getCompareLink } from '../../../../utilities/compare';

const LegacyMyCarsLabel = ({ selected, handleChange, uuid }) => {
	const labels = useLabels();
	const isDesktop = useDeviceType();
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { compareVehicles } = useSelector((state) => state.compareVehicles);
	const { widgetName, windowId } = useRequestData();

	const compareContent = () => {
		if (compareVehicles.length >= 2 && selected) {
			const compareLink = getCompareLink(compareVehicles);
			const compareXVehicles = labels
				.get('COMPARE_X_VEHICLES')
				.replace('{count}', compareVehicles.length);
			return (
				<a
					className="ddc-font-size-small"
					href={compareLink}
					onClick={() => {
						trackEvent(widgetName, windowId, {
							element: 'Compare link',
							result: 'Navigated to comparison page'
						});
					}}
				>
					{compareXVehicles}
				</a>
			);
		} else if (compareVehicles.length === 1 && selected) {
			return labels.get('SELECT_MORE_TO_COMPARE');
		} else {
			return null;
		}
	};

	const compareContentResult = compareContent();

	return (
		<>
			<label
				className={setClassNames([
					'pointer',
					useSimplifiedListing && isDesktop
						? 'ddc-font-size-xsmall'
						: 'ddc-font-size-small',
					'text-link',
					'text-link-muted',
					'mb-0',
					'mr-4'
				])}
				htmlFor={`${uuid}-compare`}
			>
				<input
					type="checkbox"
					checked={selected}
					onChange={(e) => handleChange(e)}
					id={`${uuid}-compare`}
				/>
				<span className="pl-2">{labels.get('COMPARE')}</span>
			</label>

			{compareContentResult && (
				<div className="order-1 w-100 pt-2 ddc-font-size-small">
					{compareContentResult}
				</div>
			)}
		</>
	);
};

LegacyMyCarsLabel.propTypes = {
	uuid: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default LegacyMyCarsLabel;
