import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, useSitemap } from 'wsm-common-data';
import { replaceContentWithJSX } from 'wsm-srp-utilities';
import Alert from './Alert';

const infoAlertIds = {
	EMPTY_PROFILE_DATA: 'EMPTY_PROFILE_DATA',
	LESS_INVENTORY: 'LESS_INVENTORY',
	NO_INVENTORY: 'NO_INVENTORY'
};

const InfoAlert = ({ alertMessage }) => {
	const { id, heading, amount, msg } = alertMessage;
	const sitemap = useSitemap();
	const labels = useLabels();
	const SHOP_OUR_INVENTORY = labels.get('SHOP_OUR_INVENTORY');
	const INVENTORY_LISTING_DEFAULT_AUTO_ALL = sitemap.get(
		'INVENTORY_LISTING_DEFAULT_AUTO_ALL'
	);

	let infoMsg = '';
	let infoHeading = '';

	const link = {
		'{{link}}': (
			<a
				href={INVENTORY_LISTING_DEFAULT_AUTO_ALL}
				className="text-danger"
			>
				{SHOP_OUR_INVENTORY}
			</a>
		)
	};

	switch (id) {
		case infoAlertIds.EMPTY_PROFILE_DATA: {
			const label = labels.get(msg);
			infoMsg = replaceContentWithJSX(label, link);
			break;
		}
		case infoAlertIds.LESS_INVENTORY: {
			const label = labels.get(msg);
			infoMsg = `${amount} ${label}`;
			break;
		}
		case infoAlertIds.NO_INVENTORY: {
			const headingLabel = labels.get(heading);
			const label = labels.get(msg);
			infoHeading = `${amount} ${headingLabel}`;
			infoMsg = replaceContentWithJSX(label, link);
			break;
		}
		default:
			break;
	}

	return (
		<Alert alertIconClass="ddc-icon-info" alertVariantClass="alert-info">
			<div>
				{infoHeading && (
					<div className="font-weight-bold mb-2">{infoHeading}</div>
				)}
				<div>{infoMsg}</div>
			</div>
		</Alert>
	);
};

InfoAlert.propTypes = {
	alertMessage: PropTypes.shape({
		id: PropTypes.oneOf([
			infoAlertIds.EMPTY_PROFILE_DATA,
			infoAlertIds.NO_INVENTORY,
			infoAlertIds.LESS_INVENTORY
		]),
		heading: PropTypes.string,
		amount: PropTypes.string,
		msg: PropTypes.string
	}).isRequired
};

export default InfoAlert;
