export const getCTAandWIAPIPositions = (
	viewportWidth,
	isGridLayout,
	positionPreference = 'false'
) => {
	const isTwoColumnGrid = viewportWidth < 1440 && isGridLayout;

	// index at which CTA and WIAPI cards are inserted into list of vehicles
	let firstPosition = 1; // list view case / smallscreen case

	// TEMPORARY, IF WE HAVE A POSITION PREFERENCE FROM HYUNDAI, WE NEED THE CARDS TO BE REPOSITIONED TO ACCOMODATE THEIR AD INSERTION
	if (positionPreference === 'true') {
		// index at which CTA and WIAPI cards are inserted into list of vehicles
		if (isTwoColumnGrid) {
			firstPosition = 1; // grid view with 2 columns case
		} else if (isGridLayout) {
			firstPosition = 2; // grid view with 3 columns case
		}
	} else if (positionPreference === 'false') {
		if (isTwoColumnGrid) {
			firstPosition = 2; // grid view with 2 columns case
		} else if (isGridLayout) {
			firstPosition = 3; // grid view with 3 columns case
		}
	}

	return [
		firstPosition,
		6,
		isTwoColumnGrid ? 10 : 9,
		isTwoColumnGrid ? 14 : 12
	];
};

/**
 * Method to calculate positions for cta or adcards based on num columns, size of inventory, and number of special cards allowed.
 * - First card will always be the last of the first row
 * - Last card will always be first in the second to last row
 * @param {number} invLength Length of vehicle inventory
 * @param {number} numCards Number of CTAs or Ads to get positions for
 * @param {number} columns Number of columns per row, also is the num VehicleCards per row
 * @returns {number[]} Array of positions that cards should be placed in with even distribution.
 */
export const getCtaAndAdCardPositions = (invLength, columns) => {
	const positions = [];
	const completeRows = Math.floor(invLength / columns);
	const partialRows = invLength % columns;
	const rows = invLength / columns;
	const numCards = 3;

	const first = columns - 1;
	positions.push(first);

	if (rows > 3) {
		const second = Math.round((invLength - numCards) / 2);
		positions.push(second);
	}

	if (rows > 2) {
		if (columns === 2) {
			const last = completeRows * columns - 2;
			positions.push(last);
		} else {
			const last =
				partialRows > 0
					? completeRows * columns
					: completeRows * columns - numCards;
			positions.push(last);
		}
	}

	return positions;
};
