import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import {
	selectIsTabbedLayout,
	selectIsGridLayout
} from '../../../features/layoutSlice';
import FooterSkeleton, {
	FooterSkeletonContainerClasses
} from '../skeleton/FooterSkeleton';
import {
	useCompareVehicles,
	useFooterLayoutClasses,
	useDeviceType,
	useMyCarsSiteProperties
} from '../../../hooks';
import Footer from './Footer';
import CompareContent from './CompareCheckbox/ModernMyCarsLabel/CompareContent';

const FooterContainer = ({ shouldFullyRenderCard, ...props }) => {
	const {
		shouldShowCompare,
		vehicle: { uuid }
	} = props;
	const isDesktop = useDeviceType();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const compareVehicles = useCompareVehicles(uuid);
	const { selected } = compareVehicles;
	const { legacyEnableMyCars, legacyEnableMyCarsOnVLP, enableModernMyCars } =
		useMyCarsSiteProperties();
	const isLegacyActive = useMemo(
		() =>
			!enableModernMyCars &&
			legacyEnableMyCars &&
			legacyEnableMyCarsOnVLP,
		[enableModernMyCars, legacyEnableMyCars, legacyEnableMyCarsOnVLP]
	);

	const footerContainerClasses = useFooterLayoutClasses(
		isDesktop,
		isTabbedLayout,
		shouldFullyRenderCard,
		isGridLayout,
		shouldShowCompare
	);

	const dividerClasses = setClassNames([
		'mx-4',
		isLegacyActive ? 'mb-4 mt-0' : 'my-0'
	]);

	if (!shouldFullyRenderCard) {
		footerContainerClasses.push(...FooterSkeletonContainerClasses);
	}
	if (isLegacyActive) {
		footerContainerClasses.push('pt-0 pb-4');
	}

	return (
		<>
			<hr className={dividerClasses} />
			<div className={setClassNames(footerContainerClasses)}>
				{shouldFullyRenderCard ? (
					<Footer {...props} {...compareVehicles} />
				) : (
					<FooterSkeleton />
				)}
			</div>

			{Boolean(enableModernMyCars && isTabbedLayout && selected) && (
				<div className="w-100 pb-4 ddc-font-size-small text-center">
					<CompareContent
						selected={selected}
						isInline={!isTabbedLayout}
					/>
				</div>
			)}
		</>
	);
};

FooterContainer.propTypes = {
	shouldFullyRenderCard: PropTypes.bool.isRequired,
	shouldShowCompare: PropTypes.bool,
	vehicle: PropTypes.shape({
		uuid: PropTypes.string,
		link: PropTypes.string
	}).isRequired
};

export default FooterContainer;
