import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import {
	HighlightedAttribute,
	getFuelTypeIconAndPopoverLabel,
	LocationPopoverContent,
	isValidFuelType,
	isInTransit,
	getInTransitLabel,
	getInTransitNoteLabel,
	QuickNotesButton
} from 'wsm-highlighted-attribute';
import { highlightedAttrList } from '../../../global-constants';
import { getAttributeValue } from '../../../utilities/highlightedAttributes';
import { selectIsGridLayout } from '../../../features/layoutSlice';
import { checkConcaternateAttr } from '../../../utilities/concatenateAttributeCheck';

const HighlightedAttributeContainer = ({
	highlightedAttributes,
	accountInfo
}) => {
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const { pageAlias } = useSelector((state) => state.widgetInfo);
	const {
		enableLocationHighlight,
		highlightedFuelTypeElectricIcon,
		highlightedFuelTypeHybridIcon
	} = usePrefs();
	const labels = useLabels();
	const { deviceType, locale } = useRequestData();
	const isMobile = deviceType === 'MOBILE';

	let fuelTypeAttribute;
	const quickNotesAttrs = [];
	const fuelType = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.FUEL_TYPE
	);
	if (isValidFuelType(fuelType)) {
		const [fuelTypeIcon, fuelTypePopover, fuelTypeNote] =
			getFuelTypeIconAndPopoverLabel({
				fuelType,
				electricIcon: highlightedFuelTypeElectricIcon,
				hybridIcon: highlightedFuelTypeHybridIcon,
				labels
			});
		setNewRelicCustomAttribute('fuel-highlighted-attribute', true);
		fuelTypeAttribute = (
			<HighlightedAttribute
				icon={fuelTypeIcon}
				popoverContent={fuelTypePopover}
				type="fuelType"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
			>
				{fuelType}
			</HighlightedAttribute>
		);
		quickNotesAttrs.push({
			type: 'fuelType',
			highlightedAttr: fuelTypeAttribute,
			note: fuelTypeNote
		});
	}

	let locationAttribute;
	const account = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.LOCATION
	);
	if (
		(enableLocationHighlight === 'true' || useSimplifiedListing) &&
		account?.name
	) {
		const cleanTitle = window?._?.unescape(account.name);
		const popoverAria = `${labels.get(
			'THIS_VEHICLE_IS_LOCATED_AT'
		)} ${cleanTitle}`;
		let popoverContent;
		if (Object.keys(account.address).length !== 0 || account.phone) {
			popoverContent = (
				<LocationPopoverContent
					phone={account.phone}
					id={account.id}
					address={account.address}
					isMobile={isMobile}
				/>
			);
		}
		locationAttribute = (
			<HighlightedAttribute
				icon="ddc-icon-map"
				popoverContent={popoverContent}
				type="location"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
				popoverAria={popoverAria}
			>
				{cleanTitle}
			</HighlightedAttribute>
		);
	}

	let inTransitAttribute;
	let inTransitAttributeNote;
	const inTransitData = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.ESTIMATED_DELIVERY_DATE
	);

	if (isInTransit(inTransitData?.status)) {
		const { estimatedDeliveryDate } = inTransitData;
		const inTransitText = getInTransitLabel({
			estimatedDeliveryDate,
			locale,
			labels
		});

		inTransitAttribute = (
			<HighlightedAttribute
				popoverContent={inTransitText}
				type="inTransit"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
			>
				{inTransitText}
			</HighlightedAttribute>
		);
		inTransitAttributeNote = getInTransitNoteLabel(
			accountInfo?.name,
			labels
		);
		quickNotesAttrs.push({
			type: 'inTransit',
			highlightedAttr: inTransitAttribute,
			note: inTransitAttributeNote
		});
	}

	let odometerAttribute;
	let odometerAttributeNote;
	const odometer = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.ODOMETER
	);

	/**
	 * When vehicle don't have odometer or it's value is 0, the value is still return with odometer unit 'miles', 'km', etc.
	 * Need use regex to check and only display highlighted odometer have formated odometer value and odometer unit
	 * The value is valid if contains odometer value from 1 to 3 digits or if number have more than 3 digits,
	 * we have comma for each 3 degits group.
	 */
	const isValidOdometer = checkConcaternateAttr(
		highlightedAttrList.ODOMETER,
		odometer
	);

	if (useSimplifiedListing && isValidOdometer) {
		const odometerPopoverContent = getAttributeValue(
			highlightedAttributes,
			highlightedAttrList.ODOMETER,
			true
		);
		odometerAttribute = (
			<HighlightedAttribute
				popoverContent={odometerPopoverContent}
				type="odometer"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
			>
				{odometer}
			</HighlightedAttribute>
		);
		odometerAttributeNote = labels.get('HIGHLIGHTED_ATTR_ODOMETER_EXPLAIN');

		quickNotesAttrs.push({
			type: 'odometer',
			highlightedAttr: odometerAttribute,
			note: odometerAttributeNote
		});
	}

	let certifiedAttribute;
	const certified = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.CERTIFIED
	);

	if (certified) {
		certifiedAttribute = (
			<HighlightedAttribute
				popoverContent={certified}
				type="certified"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
			>
				{certified}
			</HighlightedAttribute>
		);
	}

	let vehicleTypeAttribute;
	const vehicleType = getAttributeValue(
		highlightedAttributes,
		highlightedAttrList.CONDITION
	);

	if (vehicleType && pageAlias.includes('_AUTO_ALL')) {
		vehicleTypeAttribute = (
			<HighlightedAttribute
				popoverContent={vehicleType}
				type="condition"
				chipClasses={[
					'default mr-2',
					useSimplifiedListing ? 'my-3' : '',
					isGridLayout && !useSimplifiedListing ? 'mb-3' : ''
				]}
			>
				{vehicleType}
			</HighlightedAttribute>
		);
	}

	const isEnabled = [
		inTransitAttribute,
		locationAttribute,
		fuelTypeAttribute,
		odometerAttribute,
		certifiedAttribute,
		vehicleTypeAttribute
	].some((attr) => !!attr);

	if (isEnabled) {
		return (
			<QuickNotesButton quickNotesAttrs={quickNotesAttrs}>
				<div
					className={setClassNames([
						'vehicle-card-highlight',
						useSimplifiedListing ? 'mb-0' : 'mb-3',
						'align-self-start',
						'd-flex',
						'flex-wrap'
					])}
				>
					{inTransitAttribute}
					{locationAttribute}
					{fuelTypeAttribute}
					{odometerAttribute}
					{certifiedAttribute}
					{vehicleTypeAttribute}
				</div>
			</QuickNotesButton>
		);
	}
	return null;
};

HighlightedAttributeContainer.propTypes = {
	highlightedAttributes: PropTypes.PropTypes.arrayOf(PropTypes.shape({})),
	accountInfo: PropTypes.shape({
		name: PropTypes.string
	})
};

export default HighlightedAttributeContainer;
