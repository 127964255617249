import React from 'react';
import CtaCard from '../components/CtaCard';
import WebIntegrationListingsPlaceholder from '../components/WebIntegrationListingsPlaceholder';
import IntersectionWrapper from '../components/IntersectionWrapper';

/**
 * This returns an array of CTA cards and WIAPI placeholders that are then inserted into the vehicle card list in order
 * First position: always WIAPI placeholder (MyWallet), after 3rd vehicle card OR at the end
 * Next: either CTA or WIAPI placeholder after 6th, 10th, 15th vehicle cards OR at the end
 * Last: A WIAPI placeholder that cannot be overridden by a CTA.
 * This is located last and serves as a fallback so that there is always a place for third party content to go.
 * @param ctaCardItemlist
 * @param observerOptions
 * @param observerRef
 * @param observerCallback
 */
const createCTAandWIAPICards = (
	ctaCardItemlist,
	observerOptions,
	observerRef,
	observerCallback
) => {
	// TODO is there a way to do this generically and share the observer & ref code between all cards?
	const cards = [];
	// copy of the list we can mutate. we'll treat this like a queue and remove the first entry until it's empty
	const CTAs = [...ctaCardItemlist];
	for (let i = 0; i < 5; i++) {
		// push to card list if i is equal to index
		// ctaCardItemlist MUST be sorted by 'position'. If they are out of order this will stop working
		if (CTAs[0]?.position === `${i}`) {
			const ctaKey = `cta-card-${CTAs[0].position}`;
			cards.push(
				<IntersectionWrapper
					key={ctaKey}
					observerOptions={observerOptions}
					shouldFullyRender
					onIntersectCallback={observerCallback}
				>
					<CtaCard
						key={ctaKey}
						observerRef={observerRef}
						{...CTAs.shift()}
					/>
				</IntersectionWrapper>
			);
		} else {
			const position = parseInt(i, 10) + 1;
			const placeholderPrefix = 'listings-placeholder';
			const placeholderKey = `${placeholderPrefix}-${position}`;
			cards.push(
				<IntersectionWrapper
					key={placeholderKey}
					observerOptions={observerOptions}
					shouldFullyRender
					onIntersectCallback={observerCallback}
				>
					<WebIntegrationListingsPlaceholder
						location={placeholderKey}
						observerRef={observerRef}
					/>
				</IntersectionWrapper>
			);
		}
	}
	return cards;
};

export default createCTAandWIAPICards;
