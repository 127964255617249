import { isBrowser } from 'ws-scripts/modules/environment';
import { useFlags } from 'wsm-common-data';
import { enableModernMyCarsFlag } from '../global-constants';

export const useMyCarsSiteProperties = () => {
	const flags = useFlags();
	const enableModernMyCars = flags[enableModernMyCarsFlag];

	const enableMyCars =
		isBrowser &&
		Boolean(window?.DDC?.siteProperties?.enableMyCars === 'true');

	const enableMyCarsOnVLP =
		isBrowser &&
		Boolean(window?.DDC?.siteProperties?.enableMyCarsOnVLP === 'true');

	const legacyEnableMyCars =
		isBrowser &&
		Boolean(
			window?.DDC?.InvData?.inventory?.pageInfo?.enableMyCars ===
				'true' ||
				window?.DDC?.InvData?.inventory?.pageInfo?.enableMyCars === true
		);

	const legacyEnableMyCarsOnVLP =
		isBrowser &&
		Boolean(
			window?.DDC?.InvData?.inventory?.pageInfo?.enableMyCarsOnVLP ===
				'true' ||
				window?.DDC?.InvData?.inventory?.pageInfo?.enableMyCarsOnVLP ===
					true
		);

	return {
		enableModernMyCars,
		enableMyCars,
		enableMyCarsOnVLP,
		legacyEnableMyCars,
		legacyEnableMyCarsOnVLP
	};
};
