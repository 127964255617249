import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';

const LegacyMyCarsDetails = ({ link, onClick, label, isDesktop }) => {
	return (
		<a
			className={setClassNames([
				'more-details-link',
				'ml-auto',
				'text-link',
				'text-link-muted',
				'p-0',
				isDesktop ? 'btn-xsmall' : 'btn-small'
			])}
			href={link}
			onClick={onClick}
		>
			{label}
			<i className="ddc-icon ddc-icon-arrow1-right pl-3" />
		</a>
	);
};

LegacyMyCarsDetails.propTypes = {
	link: PropTypes.string,
	onClick: PropTypes.func,
	label: PropTypes.string,
	isDesktop: PropTypes.bool
};

export default LegacyMyCarsDetails;
