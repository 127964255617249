import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLabels, useRequestData } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { selectIsTabbedLayout } from '../../../../features/layoutSlice';
import { publishVehicleData } from '../../../../utilities/vehicleTracking';
import ModernMyCarsDetails from './ModernMycarsDetails';
import LegacyMyCarsDetails from './LegacyMyCarsDetails';
import { useDeviceType, useMyCarsSiteProperties } from '../../../../hooks';

const DetailsButton = ({ uuid, link, title }) => {
	const labels = useLabels();
	const isDesktop = useDeviceType();
	const { windowId, widgetName } = useRequestData();
	const { enableModernMyCars } = useMyCarsSiteProperties();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const { useSimplifiedListing } = useSelector((state) => state.layout);
	const isInline = !isTabbedLayout;

	const handleClick = () => {
		trackEvent(widgetName, windowId, {
			element: 'View details link',
			result: 'Navigated to VDP'
		});
		publishVehicleData(uuid);
	};

	if (enableModernMyCars) {
		return (
			<ModernMyCarsDetails
				title={title}
				link={link}
				onClick={handleClick}
				isInline={isInline}
				isDesktop={isDesktop}
			/>
		);
	} else {
		return (
			<LegacyMyCarsDetails
				link={link}
				onClick={handleClick}
				isDesktop={useSimplifiedListing && isDesktop}
				label={
					useSimplifiedListing
						? labels.get('DETAILS')
						: labels.get('MORE_DETAILS')
				}
			/>
		);
	}
};

DetailsButton.propTypes = {
	uuid: PropTypes.string,
	link: PropTypes.string,
	title: PropTypes.arrayOf(PropTypes.string)
};

export default DetailsButton;
